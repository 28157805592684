<template>
  <div class="app">
    <DefaultHeader />
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>

      <main class="main">
        <Breadcrumb id="pgiBreadCrumb" :list="list" />
        <div class="container-fluid">
          <router-view @changeSidebarNav="changeSidebarNav"></router-view>
        </div>
      </main>
      <AppAside fixed> </AppAside>
    </div>
    <DefaultFooter />
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  Breadcrumb,
} from "/node_modules/@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import DefaultHeader from "./DefaultHeader";
import DefaultFooter from "./DefaultFooter";

export default {
  name: "DefaultContainer",
  components: {
    AppSidebar,
    AppAside,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    DefaultFooter,
    DefaultHeader,
  },
  data() {
    return {
      nav: nav.items,
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) =>
          (route.name || route.meta.label) && !route.meta.hideInBreadcrumb
      );
    },
  },
  methods: {
    changeSidebarNav() {
      let menuTop = localStorage.getItem("m_top");
      let arrayMenuTop = menuTop.split(",");
      let copyNav = JSON.parse(JSON.stringify(nav.items));
      
      let menu = copyNav.filter((item) => arrayMenuTop.includes(item.code));

      menu.forEach(this.checkChildren);
      this.nav = menu;
    },

    validateNavItem() {
      let menuTop = localStorage.getItem("m_top");
      let arrayMenuTop = menuTop.split(",");
      let copyNav = JSON.parse(JSON.stringify(nav.items));
      let menu = copyNav.filter((item) => arrayMenuTop.includes(item.code));
      menu.forEach(this.checkChildren);
      // menu.filter((item, index = 0) => {
      //   if (
      //     item.code == "EKS" &&
      //     localStorage.getItem("is_authorized") == "true"
      //   ) {
      //     let newAksesLapeks = {
      //       name: "Akses Laporan Eksekutif",
      //       url: "/akses-laporan-eksekutif",
      //       icon: "fa fa-key",
      //       code: "ALE",
      //     };
      //     menu.splice(index, 1);
      //     menu.push(newAksesLapeks);
      //   }
      //   index++;
      // });

      this.nav = menu;
    },

    checkChildren(item) {
      let menuChildren = localStorage.getItem("m_child");
      let arrayMenuChildren = menuChildren.split(",");

      if (item.children != undefined) {
        if (item.children.length == 0) {
          delete item.children;
        }
        item.children = item.children.filter((child) =>
          arrayMenuChildren.includes(child.code)
        );
        item.redirect = item.children[0].url;
      }
    },

    // didLogout() {
    //   if (this.firebase != null && this.firebase.messaging.isSupported()) {
    //     // Invalidate token
    //     let fcmToken = localStorage.getItem("fcmToken");
    //     if (fcmToken != null) {
    //       let payload = new FormData();
    //       payload.append("token", fcmToken);
    //       this.$axios.post("/user/logout", payload);
    //       this.firebase.messaging().deleteToken(fcmToken);
    //     }
    //   }

    //   // Clears localstorage
    //   this.$axios.defaults.headers.common["Authorization"] = "";
    //   var version = localStorage.getItem("version");
    //   localStorage.clear();
    //   localStorage.setItem("version", version);

    //   this.$router.push("/login");
    // },

    didTapNotification() {
      this.notifCount = 0;
    },
  },

  created() {
    this.validateNavItem();

    // Auto-redirect to login page if token does not exist --> moved to router
    // if(localStorage.getItem("token") == null) {
    //     this.$router.push({ path: "/login" })
    // }

    // let that = this;

    /// ==== SETUP FIREBASE MESSAGING
    // let firebase = this.$firebase;
    // this.firebase = firebase;

    // if (firebase.messaging.isSupported()) {
    //   let messaging = firebase.messaging();

    //   messaging
    //     .requestPermission()
    //     .then(() => {
    //       console.log("Notification permission granted.");

    //       // Get Token
    //       messaging.getToken().then((token) => {
    //         // console.log("FCM Token received: " + token)
    //         localStorage.setItem("fcmToken", token);

    //         let payload = new FormData();
    //         payload.append("token", token);
    //         that.$axios.post("/user/fcm-token", payload).then(() => {
    // new Notification("Selamat datang!", {
    //     body: "Anda terhubung dengan jaringan notifikasi PGI!"
    // })
    //           });
    //         });
    //       })
    //       .catch((err) => {
    //         console.log("Unable to get permission to notify.", err);
    //       });

    //     messaging.onMessage((payload) => {
    //       new Notification(payload.notification.title, {
    //         icon: payload.notification.icon,
    //         body: payload.notification.body,
    //       });
    //       console.log("Message received. ", payload);
    //       that.notifCount++;
    //     });

    //     messaging.onTokenRefresh(() => {
    //       messaging
    //         .getToken()
    //         .then((refreshedToken) => {
    //           console.log("Token refreshed.");

    //           // Invalidate last token
    //           let lastToken = localStorage.getItem("fcmToken");

    //           if (lastToken != null) {
    //             let payload = new FormData();
    //             payload.append("token", lastToken);

    //             that.$axios.post("/user/logout", payload);
    //           }

    //           // Set new token
    //           localStorage.setItem("fcmToken", refreshedToken);

    //           let payload = new FormData();
    //           payload.append("token", refreshedToken);
    //           that.$axios.post("/user/fcm-token", payload);
    //         })
    //         .catch((err) => {
    //           console.log("Unable to retrieve refreshed token ", err);
    //         });
    //     });
    //   }
  },

  beforeDestroy() {
    //   if (this.firebase != null && this.firebase.messaging.isSupported()) {
    //     // Invalidate token
    //     let fcmToken = localStorage.getItem("fcmToken");
    //     if (fcmToken != null) {
    //       let payload = new FormData();
    //       payload.append("token", fcmToken);
    //       this.$axios.post("/user/logout", payload);
    //       this.firebase.messaging().deleteToken(fcmToken);
    //     }
    //   }
    //   this.$axios.defaults.headers.common["Authorization"] = "";
    //   var version = localStorage.getItem("version");
    //   localStorage.clear();
    //   localStorage.setItem("version", version);
  },
};
</script>
