<template>
  <TheFooter>
    <div>
      <!--footer-->
      <a href="https://www.pusatgadaiindonesia.com/">Pusat Gadai Indonesia</a>
      <span class="ml-1">&copy;2023</span>
    </div>
    <!-- <div class="ml-auto">
      <span class="mr-1">Powered by</span>
      <a href="https://www.pusatgadaiindonesia.com/"
        >IT Pusat Gadai Indonesia</a
      >
    </div> -->
  </TheFooter>
</template>

<script>
import { Footer as TheFooter } from "@coreui/vue";
export default {
  name: "DefaultFooter",
  components: {
    TheFooter,
  },
};
</script>
