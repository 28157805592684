export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-home",
      code: "DAS",
    },
    {
      name: "FPP",
      url: "/pengajuan",
      icon: "fa fa-file",
      code: "FPP",
    },
    {
      name: "Daftar (FPP)",
      url: "/daftar-pengajuan",
      icon: "fa fa-book",
      code: "DFP",
    },
    {
      name: "Persetujuan (FPP)",
      url: "/persetujuan",
      icon: "fa fa-check",
      code: "PSF",
    },
    {
      name: "Arsip (FPP)",
      url: "/arsip-fpp",
      icon: "fa fa-book",
      code: "ARS",
    },
    {
      name: "Daftar (FPP)",
      url: "/daftar-fpp-pemeliharaan",
      icon: "fa fa-book",
      code: "DPM",
    },
    {
      name: "Arsip (FPP)",
      url: "/arsip-fpp-pemeliharaan",
      icon: "fa fa-book",
      code: "APM",
    },

    // Inventaris
    // {
    //   name: "Home Inventaris",
    //   url: "/home-inventory",
    //   icon: "fa fa-home",
    //   code: "HOM",
    // },
    {
      name: "Barang Masuk",
      url: "/barang-masuk",
      icon: "fa fa-inbox",
      code: "BAM",
    },

    {
      name: "Input Data",
      url: "/input-data",
      icon: "fa fa-pencil",
      code: "IND",
      children: [
        {
          name: "Pembelian",
          url: "/input-data/pembelian",
          code: "PEM",
        },
        // {
        //   name: "Pembelian Dropship",
        //   url: "/input-data/pembelian-dropship",
        //   code: 'PED'
        // },
        {
          name: "Pengiriman",
          url: "/input-data/pengiriman",
          code: "PEI",
        },
        {
          name: "Pemakaian",
          url: "/input-data/pemakaian",
          code: "PEP",
        },
        {
          name: "Inventaris Perorangan",
          url: "/input-data/inventaris-perorangan",
          code: "INP",
        },
      ],
    },
    {
      name: "Terima Pembelian",
      url: "/terima-pembelian",
      icon: "fa fa-shopping-basket",
      code: "TPB",
    },
    {
      name: "Selisih Barang",
      url: "/selisih-barang",
      icon: "fa fa-exchange",
      code: "SBP",
      children: [
        {
          name: "Daftar Selisih",
          url: "/selisih-barang/daftar-selisih",
          code: "DSB",
        },
        {
          name: "Terima Selisih",
          url: "/selisih-barang/terima-selisih",
          code: "TSB",
        },
        {
          name: "Arsip Selisih",
          url: "/selisih-barang/arsip-selisih",
          code: "ASB",
        },
      ],
    },

    {
      name: "Stok Barang",
      url: "/stok-barang",
      icon: "fa fa-archive",
      code: "STB",
      children: [
        {
          name: "Aset Tetap",
          url: "/stok-barang/aset-tetap",
          code: "AST",
        },
        {
          name: "Aset Lancar",
          url: "/stok-barang/aset-lancar",
          code: "ASL",
        },
      ],
    },

    {
      name: "Refund Dana",
      url: "/refund",
      icon: "fa fa-retweet",
      code: "RFD",
      children: [
        {
          name: "Riwayat",
          url: "/refund-history",
          code: "RFH",

        },
        {
          name: "Pending",
          url: "/refund-pending",
          code: "RFP",

        }
      ],
    },

    // Master Cabang
    {
      title: true,
      name: "Cabang",
      class: "",
      code: "MSC",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Master Cabang",
      url: "/master-cabang",
      icon: "fa fa-database",
      code: "MSC",
      children: [
        {
          name: "Cabang",
          url: "/master-cabang/cabang",
          icon: "nav-icon icon-puzzle",
          code: "CAB",
        },
        {
          name: "Manajemen Cabang",
          url: "/master-cabang/manajemen-cabang",
          icon: "nav-icon icon-puzzle",
          code: "MNC",
        },
      ],
    },

    // Master FPP
    {
      title: true,
      name: "FPP",
      class: "",
      code: "MSF",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Master FPP",
      url: "/master-fpp",
      icon: "fa fa-database",
      code: "MSF",
      children: [
        {
          name: "Kategori",
          url: "/master-fpp/kategori-fpp",
          icon: "nav-icon icon-puzzle",
          code: "KAF",
        },
        {
          name: "Sub Kategori",
          url: "/master-fpp/sub-kategori-fpp",
          icon: "nav-icon icon-puzzle",
          code: "SKF",
        },
        {
          name: "Kategori Jenis",
          url: "/master-fpp/kategori-jenis-fpp",
          icon: "nav-icon icon-puzzle",
          code: "KJF",
        },
        {
          name: "Manajemen Akses",
          url: "/master-fpp/manajemen-akses-fpp",
          icon: "nav-icon icon-puzzle",
          code: "KPF",
        },
      ],
    },

    // Master General
    {
      title: true,
      name: "General",
      class: "",
      code: "MST",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Master General",
      url: "/master-general",
      icon: "fa fa-database",
      code: "MST",
      children: [
        {
          name: "Akses Menu",
          url: "/master-general/akses-menu",
          icon: "nav-icon icon-puzzle",
          code: "MAK",
        },
        {
          name: "Divisi",
          url: "/master-general/divisi",
          icon: "nav-icon icon-puzzle",
          code: "DIV",
        },
        {
          name: "Pemeliharaan",
          url: "/master-general/pemeliharaan",
          icon: "nav-icon icon-puzzle",
          code: "PHN",
        },
        {
          name: "Manage User",
          url: "/master-general/manage-users",
          icon: "nav-icon icon-puzzle",
          code: "MSU",
        },
      ],
    },

    // Master Inventaris
    {
      title: true,
      name: "Inventaris",
      class: "",
      code: "MAI",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Master Inventaris",
      url: "/master-inventaris",
      icon: "fa fa-database",
      code: "MAI",
      children: [
        {
          name: "Akses Inventaris",
          url: "/master-inventaris/akses-inventaris",
          code: "UAI",
        },
        {
          name: "Jenis Barang",
          url: "/master-inventaris/jenis-barang",
          code: "JEB",
        },
        {
          name: "Merk Barang",
          url: "/master-inventaris/merk-barang",
          code: "MEB",
        },
        {
          name: "Tipe Barang",
          url: "/master-inventaris/tipe-barang",
          code: "TIB",
        },
        {
          name: "Tipe Supplier",
          url: "/master-inventaris/tipe-suplier",
          code: "TSP",
        },
        {
          name: "Supplier",
          url: "/master-inventaris/suplier",
          code: "SUP",
        },
        {
          name: "Toko Online",
          url: "/master-inventaris/merchant",
          code: "TON",
        },
        {
          name: "Lokasi Pusat",
          url: "/#",
          code: "LOP",
        },
      ],
    },

    {
      name: "Approve Void",
      url: "/approve-void",
      icon: "fa fa-check",
      code: "APV",
    },

    {
      name: "Approve Void Pembelian",
      url: "/approve-void-pembelian",
      icon: "fa fa-check",
      code: "AVP",
    },
    {
      name: "Pembayaran",
      url: "/pembelian-accounting",
      icon: "fa fa-credit-card-alt",
      code: "PEA",
    },
    {
      name: "Laporan",
      url: "/laporan",
      icon: "fa fa-file",
      code: "LAP",
      children: [
        {
          name: "Laporan Stok Barang",
          url: "/laporan/stok-barang",
          code: "LSB",
        },
        {
          name: "Laporan Pemakaian",
          url: "/laporan/pemakaian",
          code: "LPE",
        },
        {
          name: "Laporan Pengiriman",
          url: "/laporan/pengiriman",
          code: "LPN",
        },
        {
          name: "Laporan Invoice",
          url: "/laporan/pembelian",
          code: "LPB",
        },
        {
          name: "Laporan Inventaris",
          url: "/laporan/inventaris",
          code: "LIN",
        },
      ],
    },

    {
      name: "Ubah Password",
      url: "/dashboard",
      icon: "fa fa-key",
    },
  ],
};
