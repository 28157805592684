<template>
  <AppHeader fixed>
    <SidebarToggler class="d-lg-none" display="md" mobile />
    <b-link class="navbar-brand" to="#">
      <img class="navbar-brand-full" :src="$logoPath" height="40" alt="PGI Logo" />
      <img class="navbar-brand-minimized" :src="$logoCollapseSidebar" width="30" height="30" alt="CoreUI Logo" />
    </b-link>
    <SidebarToggler class="d-md-down-none" display="lg" :defaultOpen="true" />

    <b-navbar-nav class="ml-auto">
      <b-nav-item class="header-user-info">
        <div class="header-user-data">
          <b-dropdown variant="light" :text="name">
            <b-dropdown-header id="dropdown-header-label">
              {{ role }}
            </b-dropdown-header>
            <b-dropdown-item href="#" @click="$bvModal.show('m-change-password')"><i class="fa fa-key"></i> Ubah Kata
              Sandi</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-nav-item>

      <b-nav-item class="header-user-info-mobile">
        <div class="header-user-data">
          <b-dropdown variant="light">
            <template slot="button-content">
              <i class="fa fa-user"></i>
            </template>
            <b-dropdown-header id="dropdown-header-label">
              {{ name + " - " + role }}
            </b-dropdown-header>
            <b-dropdown-item href="#" @click="$bvModal.show('m-change-password')"><i class="fa fa-key"></i> Ubah Kata
              Sandi</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-nav-item>

      <b-nav-item @click="redirectToNotif" class="header-notif-link notikasi-box">
        <!-- <div class="notifikasi-wrap heartbeat">
          <div class="notifikasi-indikator"></div>
        </div> -->
        <i class="fa fa-bell"></i>
      </b-nav-item>
      <b-nav-item class="header-logout-link" @click="logout">
        <i v-show="!this.$store.state.isLoggingOut" class="fa fa-sign-out ml-1"></i>

        <i v-show="this.$store.state.isLoggingOut" class="spinner-border spinner-border-sm"></i>

        <span class="font-weight-bold">Keluar</span>
      </b-nav-item>
    </b-navbar-nav>

    <!-- Change Password -->
    <b-modal id="m-change-password" size="md" ref="my-modal" title="Ubah Kata Sandi" @hidden="hideModal"
      no-close-on-backdrop no-close-on-esc hide-footer>
      <b-form @submit.prevent="showSweetAlertChangePassword">
        <b-form-group id="fg-create-password" label="Kata Sandi" label-for="create-cabang-fpp" class="required">
          <b-form-input v-model="form.password" placeholder="Kata sandi" type="text" required></b-form-input>
          <small class="text-danger" v-if="!$v.form.password.required">Kata sandi tidak boleh kosong</small>
          <small class="text-danger" v-if="form.password && !$v.form.password.minLength">Kata sandi minimal 6
            karakter</small>
        </b-form-group>

        <b-form-group id="fg-create-password" label="Ulangi Kata Sandi" label-for="create-cabang-fpp"
          class="mt-2 required">
          <b-form-input v-model="form.password_confirm" placeholder="Ulangi kata sandi" type="text"
            required></b-form-input>
          <small class="text-danger" v-if="!$v.form.password_confirm.required">Ulangi kata sandi tidak boleh
            kosong</small>
          <small class="text-danger" v-if="form.password_confirm && !$v.form.password_confirm.sameAsPassword
            ">Kata Sandi dan Ulangi Kata Sandi harus sama</small>
        </b-form-group>

        <b-button type="submit" variant="primary" :disabled="this.$v.form.$invalid">
          <span v-show="!isSubmitting"><i class="fa fa-save"></i> Simpan & Ubah Kata Sandi</span>

          <span v-show="isSubmitting">
            <span class="spinner-border spinner-border-sm"></span> Simpan & Ubah
            Kata Sandi
          </span>
        </b-button>
      </b-form>
    </b-modal>
  </AppHeader>
</template>
<script>
import {
  Header as AppHeader,
  SidebarToggler,
  AsideToggler,
} from "/node_modules/@coreui/vue";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import { mapGetters } from "vuex";
import { validationMixin } from "/node_modules/vuelidate";
import {
  required,
  minLength,
  sameAs,
} from "/node_modules/vuelidate/lib/validators";

export default {
  name: "DefaultHeader",
  mixins: [validationMixin],
  data: () => {
    return {
      name: localStorage.getItem("name"),
      role: localStorage.getItem("role"),
      form: {
        password: "",
        password_confirm: "",
      },
      isSubmitting: false,
    };
  },
  validations: {
    form: {
      password: { required, minLength: minLength(6) },
      password_confirm: { required, sameAsPassword: sameAs("password") },
    },
  },
  components: {
    AsideToggler,
    AppHeader,
    DefaultHeaderDropdownAccnt,
    SidebarToggler,
  },
  watch: {},
  computed: {
    ...mapGetters(["logged", "token"]),
  },
  methods: {
    confirm: function () {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.showSweetAlertChangePassword();
      }
    },

    hideModal: function () {
      this.$refs["my-modal"].hide();
      this.onReset();
    },

    redirectToNotif() {
      // this.$router.push("/refund-pending")
      return
    },

    onReset: function () {
      this.form = {
        password: "",
        password_confirm: "",
      };
    },

    showSweetAlertChangePassword: function () {
      let form = {
        password: this.form.password,
        password_confirmation: this.form.password_confirm,
      };

      this.isSubmitting = true;
      this.$axios
        .post("/api/auth/change-password", form)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: "Sukses!",
              html: "<span>Kata sandi berhasil diubah</span><br /><small>Silahkan melakukan login kembali menggunakan kata sandi yang sudah anda diperbarui</small>",
              icon: "success",
              showConfirmButton: true,
              confirmButtonColor: "#1e73be",
              confirmButtonText: "Lanjut",
            }).then((result) => {
              if (result.isConfirmed) {
                this.onReset();
                this.logout();
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            title: "Gagal!",
            html: "Kata sandi gagal diubah!",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .finally(() => (this.isSubmitting = false));
    },
    logout() {
      this.$store.dispatch("destroyAuthentication").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>

<style>
.header-user-info-mobile {
  display: none;
}

.dropdown-header {
  background: #fff;
  padding: 8px 10px;
}

.dropdown-item {
  padding: 10px 10px;
}

.dropdown-item i {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    position: static;
    display: contents;
  }
}

@media only screen and (max-width: 600px) {
  .header-user-info-mobile {
    display: block;
  }
}
</style>
